<template>
  <table class="table w-full address-table">
    <thead>
      <tr>
        <th v-html="$tk('AddressList.Active')"></th>
        <th v-html="$tk('Common.General.Name')"></th>
        <th v-html="$tk('Common.Address.Street')"></th>
        <th v-html="$tk('Common.Address.PostCode')"></th>
        <th v-html="$tk('Common.Address.PostPlace')"></th>
        <th v-html="$tk('Common.Address.Country')"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(address, index) in addresses"
        :key="index"
        :class="{
          'hover:bg-orange-200 cursor-pointer': address.typeId === 3 && address.statusId === 0,
          active: address.id === activeAddressId
        }"
        @click.prevent="edit(address)"
      >
        <td>
          <FontAwesomeIcon
            :icon="['far', 'check']"
            v-if="!address.inactive"
          />
        </td>
        <td>{{ address.name }}</td>
        <td>{{ address.street }}</td>
        <td>{{ address.postalCode }}</td>
        <td>{{ address.postalName }}</td>
        <td>{{ address.countryName }}</td>
        <td class="text-right">
          <button
            v-if="address.statusId !== 1"
            class="text-red-500"
            :title="$t('Common.Actions.Delete')"
            @click.prevent.stop="deleteAddress(address.id)"
          >
            <FontAwesomeIcon :icon="['far', 'trash-alt']" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import api from "../api"

export default {

  name: "AddressList",

  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    activeAddressId: { type: String, default: '' }
  },

  methods: {
    deleteAddress(id) {
      try {
        api.addressDelete(id)
        this.$emit("deleted", id)
      } catch (e) {
        this.$notifyError(
          this.$tk("AddressList.ErrorMessage"),
          this.$formatApiError(e)
        )
      }
    },
    edit(address) {
      if (address.typeId === 3 && address.statusId === 0) {
        this.$emit('edit', address)
      }
    }
  }
}
</script>

<style scoped>
.address-table thead tr th {
  @apply border-b-2 text-left text-sm
}

.address-table th,
.address-table td {
  @apply p-2
}

.address-table tbody tr:first-child td {
  @apply text-green-600
}

.address-table tbody tr td {
  @apply border-b-2 text-sm
}

tr.active {
  @apply bg-orange-100
}
</style>
<template>
  <div class="flex-1 overflow-x-auto overflow-y-auto rounded">

    <PLoader v-if="loading" />

    <PTable 
      v-else 
      v-on:select="locationSelected" 
      :data="locations"
      :cols="cols" 
    ></PTable>

    <portal @close="showCreateLocation = false" to="actions">
      <PButton 
        class="ml-4" 
        color="primary"
        @click="showCreateLocation = true"         
      >
        <span v-html="$tk('LocationList.NewLocation')"></span>
      </PButton>
    </portal>

    <LocationDialog 
      v-if="showCreateLocation" 
      @created="addLocation" 
      @close="showCreateLocation = false"
      @updated="load"
    />
  
    <LocationDialog 
      v-if="showEditLocation" 
      @close="showEditLocation = false" 
      :locationId="selectedLocationId"
      @updated="load"
    />

  </div>
</template>

<script>
import api from "../api";
import { mapGetters } from "vuex";
import PLoader from "@/components/PLoader";
import PTable from "@/components/PTable";
import LocationDialog from "./dialogs/LocationDialog"

export default {
  
  name: "LocationList",
  
  data () {
    return {
      locations: [],
      loading: true,
      selectedLocationId: undefined,
      showEditLocation: false,
      showCreateLocation: false,
      cols: [
        { text: this.$tk("LocationList.LocationNumber"), value: "id", sortable: true, type: "id"},
        { text: this.$tk("Common.General.Name"), value: "name", sortable: true },
        { text: this.$tk("Common.General.Address"), value: "address", sortable: true },
        { text: "GLN", value: "gln", sortable: true }
      ],
    };
  },

  computed: {
    ...mapGetters(["customer"]),
  },

  components: {
    PLoader,
    PTable,
    LocationDialog
  },

  methods: {
    locationSelected (location) {
      this.showEditLocation = true
      this.selectedLocationId = location.id;
    },

    addLocation (location) {
      this.showCreateLocation = false
      this.locations.unshift(location)
    },

    async load () {
      this.locations = await api.locationsGet(this.customer.id);      
    }
  },

  async created () {
    await this.load()    
    this.loading = false
  },

};
</script>
